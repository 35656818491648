// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
Turbolinks.start();
ActiveStorage.start();

// External packages
import CookiesEuBanner from "cookies-eu-banner";
import "select2/dist/js/select2.min";
import "select2/dist/js/i18n/pt-BR";
import "@fortawesome/fontawesome-free/js/all";

// Custom Js
import bsPopoversInit from "./src/bs_popover_init";
import gtag from "./src/g_analytics";
import select2Init from "./src/select2_init";

document.addEventListener("turbolinks:load", () => {
  bsPopoversInit('[data-bs-toggle="popover"]');
  new CookiesEuBanner(() => {
    gtag("config", process.env.G_ANALYTICS_ID || "G-1V800M6J9R");
  });
  select2Init("select.select2-init");
});
