export default function select2Init(selector = "select") {
  $(selector).each(function () {
    $(this).select2({
      debug: true,
      allowClear: true,
      language: "pt-BR",
      minimumInputLength: $(this).hasClass("location-select2") ? 3 : 0,
      minimumResultsForSearch: 10,
      placeholder: $(this).hasClass("location-select2")
        ? "Escolha uma localização"
        : "Escolha uma opção",
      ajax: $(this).hasClass("location-select2") ? ajaxParams() : null,
    });
    $(this).on("select2:open", () => {
      document.querySelector(".select2-container--open .select2-search__field").focus();
    });
  });
}

function ajaxParams() {
  return {
    delay: 250,
    url: "https://nominatim.openstreetmap.org/search",
    dataType: "json",
    data: function (params) {
      return {
        email: process.env.MAIN_EMAIL,
        format: "jsonv2",
        limit: 5,
        "accept-language": "pt-BR",
        q: params.term,
      };
    },
    processResults: function (data) {
      const results = data.map((result) => {
        return { id: result.display_name, text: result.display_name };
      });
      return { results: results };
    },
  };
}
